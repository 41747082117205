<template>
  <div class="custom-search-result">
    <div class="custom-search-result-title">
      <div>项目列表</div>
      <el-button class="btn" @click="addEvent">新增项目</el-button>
    </div>
    <div class="custom-search-result-tip">
      <i class="el-icon-info active-color"></i>
      <span>查询结果：</span>
      <span class="active-color">{{ total }}个</span>
    </div>
    <table-component
      :total="total"
      @changePage="changePage"
      @cell-click="cellClick"
      :currentPage="page"
      :tableTh="tableTh"
      :tableData="tableData"
      :height="height"
    >
      <el-table-column
        width="80"
        label="优先级"
        class-name="priority"
        v-if="from != 'pannel'"
      >
        <template slot-scope="scope">
          <div :class="scope.row.priorityClass">
            {{ scope.row.priority }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        width="100"
        label="审核状态"
        v-if="!$route.query.tab || $route.query.tab == 1"
      >
        <template slot-scope="scope">
          <div
            :class="
              scope.row.approveStatus == 0 ? 'active-color' : 'approveStatus'
            "
            @click="cellClick(scope.row)"
          >
            {{ scope.row.approveStatus | approveFilter }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="100" label="操作" v-else>
        <template slot-scope="scope">
          <div class="active-color" @click="cellClick(scope.row)">
            {{ operate[$route.query.tab - "" - 1] }}
          </div>
        </template>
      </el-table-column>
    </table-component>
  </div>
</template>

<script>
import TableComponent from "@/components/tableComponent";
import { mapState } from "vuex";
export default {
  components: {
    TableComponent,
  },
  props: {
    from: String,
  },
  data() {
    return {
      searchVal: "",
    };
  },
  computed: {
    ...mapState({
      tableData: (state) => state.projectTable.projectList,
      tableTh: (state) => state.projectTable.tableTh,
      total: (state) => state.projectTable.total,
      page: (state) => state.projectTable.page,
      operate: (state) => state.projectTable.operate,
    }),
  },
  mounted() {
    this.getData();
  },
  methods: {
    addEvent() {
      //新增项目
      this.$store.commit("RESTE_DATA");
      this.$store.commit("SET_DIALOGVISIBLE", true);
      this.$store.commit("SET_ACTIVE", 0);
      this.$store.commit("SET_CONDITION", { val: 2 });
      this.$store.commit("RESET_STATUS");
    },
    cellClick(e) {
      //点击查看
      const num = this.$route.query.tab ? this.$route.query.tab : 1;
      this.$store.commit("SET_ID", e.id);
      this.$store.commit("SET_ACTIVE", num - 1);
      this.$store.commit("SET_DIALOGVISIBLE", true);
      this.$store.commit("SET_CHECK", num - 1);
      if (e.finishStep.indexOf(num) == -1) {
        this.$store.commit("SET_CONDITION", { val: 2 });
        this.$store.commit(
          `${this.$store.getters.getByActive.type}/RESTE_DATA`
        );
      } else {
        this.$store.commit("SET_CONDITION", { val: 1 });
      }
    },

    getData() {
      this.getprojectList();
      this.getprojectListTotal();
    },
    getprojectList() {
      this.$store.dispatch("projectList", {
        keyword: this.searchVal ? this.searchVal : "",
      });
    },
    getprojectListTotal() {
      this.$store.dispatch("projectTotalNum", {
        itemType: 0,
        keyword: this.searchVal ? this.searchVal : "",
      });
    },
    changePage(val) {
      this.$store.commit({
        type: "SET_PAGE",
        count: val,
      });
      this.getData();
    },
  },
  filters: {
    approveFilter(status) {
      switch (status) {
        case 1:
          return "已审核";
        case 0:
          return "未审核";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.approveStatus {
  color: #69748d;
}
.custom-search-result {
  padding: 30px 24px;
  .custom-search-result-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
  }
  .custom-search-result-tip {
    color: #374567;
    font-size: 14px;
    padding: 9px 22px;
    background: #e6f7ff;
    border: 1px solid #bae7ff;
    margin: 20px 0 16px;
    i {
      margin-right: 9px;
      vertical-align: middle;
    }
  }
}
</style>