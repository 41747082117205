<template>
  <div>
    <table-template
      :tableTh="tableTh"
      :tableData="tableData"
      :height="height"
      @cell-click="handleClick"
    >
      <slot></slot>
    </table-template>
    <el-row
      v-if="tableData.length > 0"
      type="flex"
      align="middle"
      justify="end"
      class="patination"
    >
      <div v-if="showPatination">
        <patination-template
          :total="total"
          :pageSize="pageSize"
          :currentPage="currentPage"
          @changePage="changePage"
        ></patination-template>
      </div>
    </el-row>
  </div>
</template>

<script>
import patinationTemplate from "@/components/paginations";
import tableTemplate from "@/components/tables";
export default {
  props: {
    tableTh: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
    isClick: {
      type: Boolean,
    },
    height: {
      type: String,
    },
    total: {
      type: Number,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    showPatination: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    patinationTemplate,
    tableTemplate,
  },
  methods: {
    handleClick(data) {
      this.$emit("cell-click", data);
    },
    changePage(val) {
      this.$emit("changePage", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  padding-top: 20px;
}
</style>