<template>
  <div id="custom">
    <div class="custom-box">
      <filter-template
        :filterType="filterType"
        @reset="reset"
        @submit="handleFilter"
        @search="searchEvent"
      ></filter-template>
      <table-template ref="table"></table-template>
      <add-template
        :editData="editData"
        ref="attractAdd"
        @createSuccess="createSuccess"
      ></add-template>
    </div>
  </div>
</template>

<script>
import FilterTemplate from "../components/filter/filter";
import addTemplate from "./project_info/index";
import tableTemplate from "./table";
export default {
  components: {
    FilterTemplate,
    tableTemplate,
    addTemplate,
  },
  data() {
    return {
      showPorject: false,
      editData: null,
      searchVal: "",
      filterType: [
        {
          title: "优先级",
          type: "status",
          checkVal: [],
          disabled: true,
          data: [
            {
              name: "不限",
              value: 0,
            },
            {
              name: "A",
              value: 1,
            },
            {
              name: "B",
              value: 2,
            },
            {
              name: "C",
              value: 3,
            },
            {
              name: "D",
              value: 4,
            },
          ],
        },
        {
          title: "产业类别",
          type: "industry",
          checkVal: [],
          disabled: true,
          data: [
            {
              name: "不限",
              value: 0,
            },
            {
              name: "石油装备产业",
              value: 1,
            },
            {
              name: "海工装备产业",
              value: 2,
            },
            {
              name: "精密铸造与高端装备产业",
              value: 3,
            },
            {
              name: "生物医药产业",
              value: 4,
            },
            {
              name: "新能源与低碳科技产业",
              value: 5,
            },
          ],
        },
      ],
    };
  },

  filters: {
    approveFilter(status) {
      switch (status) {
        case 1:
          return "已审核";
        case 0:
          return "未审核";
      }
    },
  },
  methods: {
    searchEvent(val) {
      this.$store.commit({
        type: "SET_PAGE",
        count: 1,
      });
      this.$refs.table.searchVal = val;
      this.$refs.table.getData(true);
    },
    editEvent() {
      this.$set(
        this,
        "editData",
        this.$refs.projectTable.$refs.detail.detailData
      );
      this.$refs.projectTable.$refs.detail.close();
      this.$refs.attractAdd.open(true);
    },
    addEvent() {
      //新增事项
      this.$set(this, "editData", null);
      this.$refs.attractAdd.open();
    },
    createSuccess() {
      this.handleFilter();
    },
    reset() {
      this.filterType.forEach((val) => {
        val.checkVal = [];
      });
    },

    handleFilter() {
      this.getData(true);
    },
  },
};
</script>

<style lang="scss" scoped>
#custom {
  background: #f3f7ff;
  padding: 25px 18px;
  box-sizing: border-box;
  .custom-box {
    background: #fff;
  }
}
::v-deep .el-input-group__append {
  background: #00a0e9;
  color: #fff;
  border-color: #00a0e9;
  border-radius: 0;
}
</style>