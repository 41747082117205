<template>
  <el-table
    header-row-class-name="headclass"
    :data="tableData"
    style="width: 100%"
    @cell-click="handleClick"
    row-key="itemId"
    :height="height"
    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
  >
    <el-table-column
      :header-align="value.headerAlgin"
      v-for="value in tableTh"
      :key="value.id"
      :class-name="value.className"
      :label="value.title"
      :prop="value.type ? value.type : ''"
      :width="value.width"
    >
    </el-table-column>
    <slot></slot>
  </el-table>
</template>

<script>
export default {
  props: {
    tableTh: {
      type: Array,
    },
    tableData: {
      type: Array,
    },
    isClick: {
      type: Boolean,
    },
    height: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick(data, column) {
      if(column.className&&column.className.indexOf('clicked')!=-1){
        this.$emit('cell-click',data);
      }
      // if (column.property == "entName"&&column.className.indexOf('active-color')!=-1) {
      //   //查看企业详情
      //   this.$router.push("company/" + row.id);
      //   return;
      // } else if (
      //   (column.property == "matter" ||
      //   column.property == "itemName" ||
      //   column.property == "projName")&&column.className.indexOf('active-color')!=-1
      // ) {
      //   this.$emit("showMatter", row);
      //   return;
      // } else {
      //   this.$emit("handleClick", row);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .headclass {
  background-color: #f0f4fc;
  th {
    background: transparent;
    color: #374567;
    font-weight: 500;
  }
  .th-right {
    text-align: center;
  }
}
::v-deep .el-table--enable-row-transition .el-table__body td {
  margin-left: 30px;
}
::v-deep .cell {
  white-space: nowrap;
}
::v-deep .line-max-2 .cell {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  white-space: inherit;
}

.el-table {
  font-size: 14px;
}
::v-deep .active-color:hover {
  cursor: pointer;
}
::v-deep .el-table__expand-icon {
  color: #00a0e9;
}
::v-deep .el-table--border::after,
.el-table--group::after,
.el-table::before {
  height: 0;
}
::v-deep .priority {
  font-weight: 600;
}
::v-deep .priority_A {
  color: #fc555e;
}
::v-deep .priority_B {
  color: #8fc31e;
}
::v-deep .priority_C {
  color: #faad14;
}
::v-deep .priority_D {
  color: #69748d;
}
</style>