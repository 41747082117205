<template>
  <el-pagination
    :page-sizes="[10]"
    :layout="layout"
    :total="total - ''"
    @current-change="handlePageChange"
    :page-size="pageSize"
    :current-page="currentPage"
  >
  </el-pagination>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
    },
    layout: {
      type: String,
      default: "prev, pager, next,sizes,jumper",
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handlePageChange(val) {
      this.$emit("changePage", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-pagination {
  height: 40px;
  display: flex;
  align-items: center;
}
::v-deep .el-input__inner {
  height: 29px !important;
}
::v-deep .el-pagination__sizes,
::v-deep .el-pagination__jump {
  // height: 40px !important;
  margin-left: 0;
}
::v-deep .el-pager li {
  border: 1px solid #e1e6f0;
  padding: 0;
  min-width: 27px;
  height: 27px;
  margin-right: 10px;
  color: #374567;
}
::v-deep .el-pager li.active {
  color: #fff;
  background: #00a0e9;
  border-color: #00a0e9;
}
::v-deep .el-pager li.active + li {
  border-left: 0.006944rem solid #e1e6f0;
}
::v-deep .btn-prev,
::v-deep .btn-next {
  border: 1px solid #e1e6f0;
  padding: 0;
  min-width: 27px;
  height: 27px;
  margin-right: 10px;
  color: #374567;
}
</style>